import { Chain } from "~/types";

export const CurrencyIcon = ({ chain, color }: { chain: Chain; color?: string }) =>
  chain === Chain.Ethereum ? (
    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Union">
        <path
          d="M3.66258 0.232692C3.58293 0.119403 3.41502 0.119401 3.33537 0.232689L0.132899 4.78735C0.0628036 4.88704 0.0974024 5.02571 0.206113 5.08079L3.40858 6.70335C3.4654 6.73215 3.53254 6.73214 3.58937 6.70335L6.79175 5.08079C6.90046 5.02571 6.93506 4.88704 6.86496 4.78735L3.66258 0.232692Z"
          fill={color ?? "#999999"}
        />
        <path
          d="M0.428514 5.99068C0.331777 5.94169 0.23716 6.06014 0.306309 6.14366L3.34496 9.81398C3.42493 9.91057 3.57304 9.91059 3.65304 9.81402L6.69354 6.14362C6.76272 6.06012 6.66813 5.94164 6.57138 5.99061L3.58931 7.49983C3.53252 7.52858 3.46543 7.52857 3.40865 7.49981L0.428514 5.99068Z"
          fill={color ?? "#999999"}
        />
      </g>
    </svg>
  ) : (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame">
        <path
          id="Vector"
          d="M10.1034 4.9711C10.2751 3.86779 9.40144 3.27472 8.20681 2.87909L8.59436 1.38456L7.64815 1.15787L7.27088 2.61305C7.02213 2.5534 6.76667 2.49719 6.51278 2.44147L6.89278 0.976691L5.94716 0.75L5.5594 2.24404C5.35356 2.19897 5.15137 2.15444 4.95522 2.10751L4.95632 2.10281L3.65147 1.78952L3.39977 2.7612C3.39977 2.7612 4.10178 2.91592 4.08698 2.92545C4.47014 3.0174 4.53944 3.26127 4.52793 3.45457L4.08648 5.15718C4.11286 5.16363 4.14708 5.17295 4.18485 5.18754C4.15328 5.18 4.11969 5.17177 4.08483 5.16375L3.46607 7.54887C3.41924 7.66079 3.30038 7.82877 3.0325 7.76498C3.04198 7.77819 2.34478 7.59997 2.34478 7.59997L1.875 8.64135L3.10634 8.93649C3.33541 8.99172 3.55989 9.04951 3.78095 9.10385L3.3894 10.6155L4.33451 10.8422L4.72227 9.34659C4.98047 9.41398 5.23103 9.47614 5.47633 9.53474L5.08988 11.0233L6.03613 11.25L6.42764 9.74117C8.04113 10.0348 9.25434 9.9164 9.765 8.51321C10.1765 7.38349 9.74452 6.73186 8.89567 6.30693C9.51393 6.16984 9.97962 5.77886 10.1038 4.97122L10.1035 4.97102L10.1034 4.9711ZM7.94158 7.88582C7.64916 9.01555 5.67084 8.40485 5.02944 8.25171L5.54904 6.24902C6.19039 6.40297 8.24719 6.70763 7.94162 7.88582H7.94158ZM8.23421 4.95472C7.96746 5.98233 6.32088 5.46026 5.78675 5.33225L6.25783 3.51592C6.79196 3.64394 8.51208 3.88287 8.23429 4.95472H8.23421Z"
          fill={color ?? "#999999"}
        />
      </g>
    </svg>
  );
